import React from "react"
import * as styles from "./office-hours.module.scss"

export const OfficeHours = ({
  simple_office_hours,
  simple_service_hours,
  location,
}) => {
  if (
    simple_office_hours.value &&
    simple_service_hours.value &&
    location === "contact-us"
  )
    return (
      <div className={`container ${styles.officeHours}`}>
        <span>
          <strong>Office Hours: </strong> {simple_office_hours.value}
        </span>
        <span>
          <strong>Service Hours: </strong> {simple_service_hours.value}
        </span>
        <hr className={styles.sectionDivider} />
      </div>
    )
  else return null
}

export default OfficeHours
