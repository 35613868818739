import React, { useContext } from "react"
import { AppContext } from "../context/context"
import { Link } from "gatsby"

const ServiceArea = ({ serviceArea }) => {
  const { whereWeServiceActive, whereWeServiceSlug } = useContext(AppContext)
  const { label, slug } = serviceArea
  if (slug.value !== "" && whereWeServiceActive === "true") {
    return (
      <li>
        <Link to={`/${whereWeServiceSlug}/${slug.value}`}>{label.value}</Link>
      </li>
    )
  } else if (slug.value !== "" && whereWeServiceActive === "false") {
    return (
      <li>
        <Link to={"/" + slug.value}>{label.value}</Link>
      </li>
    )
  } else {
    return <li>{label.value}</li>
  }
}

export default ServiceArea
