import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { AppContext } from "../context/context"
const LocalBusinessSchema = ({
  serviceArea,
  page,
  rdLink,
  rdImage,
  socialMedia,
}) => {
  const {
    company_name,
    primary_logo,
    corporate_head_office,
    siteUrl,
    whereWeServiceActive,
    whereWeServiceSlug,
  } = useContext(AppContext)
  const { business_hours, service_pages, price_range } =
    corporate_head_office.value[0].elements
  const getUrl = () => {
    return whereWeServiceActive === "true"
      ? `"${siteUrl}/${whereWeServiceSlug}/${page}"`
      : `"${siteUrl}/${page}"`
  }
  const getOpeningHours = () => {
    return business_hours.value.map(({ elements }) => {
      const { day_of_the_week, open, close } = elements
      return `{"@type":"OpeningHoursSpecification","dayOfWeek":["${day_of_the_week.value[0].name}"], "opens":"${open.value}", "closes":"${close.value}"}`
    })
  }

  const getSocialMediaList = () => {
    if (socialMedia.length === 0) return ""

    return `"sameAs": [ ${socialMedia.map(social => {
      const { linkUrl } = social
      return `"${linkUrl}" `
    })}]`
  }

  const getListElement = () => {
    return service_pages.value.map(({ elements }) => {
      const { page_title, slug } = elements
      return `{"@type":"Offer","itemOffered":{"@type":"Service","name":"${page_title.value}","url":"${siteUrl}/${slug.value}"}}`
    })
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context":"http://schema.org",
            "@type":"LocalBusiness",
            "name":"${company_name.value}",
            ${getSocialMediaList()},
            "logo":"${primary_logo.value[0].url}",
            "areaServed": [
              {"@type": "City", "name": "${serviceArea}", "@id": "${rdLink}" }
            ],
            "image":"${rdImage}",
            "priceRange":"${price_range.value}",
            "url":${getUrl()},
            "openingHoursSpecification":[
              ${getOpeningHours()}
            ],
            "hasOfferCatalog": {
              "@type": "OfferCatalog",
              "itemListElement":[
                ${getListElement()}
              ]
            }
          }
        `}
      </script>
    </Helmet>
  )
}

export default LocalBusinessSchema
