import React from "react"
import { graphql } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Layout from "../components/layout"
import SEO from "../components/seo/seo"
import BlogHero from "../components/blog/BlogHero"
import FormStub from "../components/form-stub/FormStub"
import ContentBlocks from "../components/content-blocks/ContentBlocks"
import GlobalFooter from "../components/global-footer/GlobalFooter"
import BlogTags from "../components/blog/BlogTags"
import BlogSchema from "../components/schema/BlogSchema"
import GoogleRating from "../components/google-rating-banner/GoogleRating"

const Blog = ({ data, pageContext }) => {
  const [post] = data.allKontentItemPost.nodes
  const [postV2] = data.allKontentItemPostCopy.nodes
  const {
    heading,
    published_date,
    content_blocks,
    first_content_section,
    main_content_section,
    tags,
    seo_metadata__meta_title,
    seo_metadata__meta_description,
    global_footer,
    author,
    featured_image,
    cta_form_stub,
  } = post ? post.elements : postV2.elements
  const {
    pestroutes_reviews,
    corporate_head_office,
    reviews,
    review_images,
    google_rating_banner,
    enable_buy_online,
  } = data.kontentItemSettings.elements
  const { tracking_number } = corporate_head_office.value[0].elements

  const crumbLabel = heading.value

  const name = author.value[0]?.elements.name.value

  const { category_name, slug } = post
    ? post.elements.category.value[0].elements
    : postV2.elements.category.value[0]?.elements
    ? postV2.elements.category.value[0].elements
    : { category_name: "Home Pest Control", slug: "placeholder-value" }

  const google_url = google_rating_banner.value[0]?.elements.google_url

  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <SEO
        title={seo_metadata__meta_title.value}
        description={seo_metadata__meta_description.value}
      />
      <BlogSchema
        heading={heading.value}
        description={seo_metadata__meta_description.value}
        published={published_date.value}
        image={featured_image.value[0]}
      />
      <Breadcrumb
        crumbs={crumbs}
        crumbSeparator=" > "
        hiddenCrumbs={["/blog/posts", "/blog/post"]}
        crumbLabel={crumbLabel}
      />
      {google_url?.value && <GoogleRating url={google_url} />}
      {cta_form_stub?.value.length > 0 && (
        <FormStub
          content={cta_form_stub.value[0]}
          phoneNumber={tracking_number.value}
          currentPage={pageContext.breadcrumb.location}
        />
      )}
      <div className="container">
        <BlogHero
          title={heading.value}
          category={category_name.value}
          slug={slug.value}
          published={published_date.value}
          author={name}
        />
      </div>
      {content_blocks?.value?.length && (
        <ContentBlocks
          content={content_blocks}
          meta={pageContext}
          enableBuyOnline={enable_buy_online}
          isPost={true}
        />
      )}
      {first_content_section && (
        <ContentBlocks
          content={{
            value: [
              {
                elements: {
                  first_content_section,
                  main_content_section,
                  featured_image,
                },
              },
            ],
          }}
        />
      )}
      <BlogTags tagsArr={tags.value} />
      {global_footer?.value?.length > 0 && (
        <GlobalFooter
          content={global_footer}
          phone={tracking_number}
          pestroutesReviews={pestroutes_reviews}
          reviewImages={review_images}
          staticReviews={reviews}
        />
      )}
    </Layout>
  )
}
export const getPost = graphql`
  query getPost($slug: String!) {
    allKontentItemPostCopy(
      filter: { elements: { slug: { value: { eq: $slug } } } }
    ) {
      nodes {
        elements {
          heading {
            value
          }
          cta_form_stub {
            value {
              ... on kontent_item_form_stub_banner {
                id
                elements {
                  icon {
                    value {
                      url
                      description
                    }
                  }
                  heading {
                    value
                  }
                  button_text {
                    value
                  }
                  subheading {
                    value
                  }
                  fields {
                    value {
                      ... on kontent_item_form_field {
                        id
                        elements {
                          form_field {
                            value {
                              codename
                            }
                          }
                          field_type {
                            value {
                              codename
                            }
                          }
                          label {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          published_date {
            value(formatString: "MMMM DD, YYYY")
          }
          seo_metadata__meta_title {
            value
          }
          seo_metadata__meta_description {
            value
          }
          author {
            value {
              ... on kontent_item_author {
                id
                elements {
                  name {
                    value
                  }
                }
              }
            }
          }
          category {
            value {
              ... on kontent_item_category {
                elements {
                  category_name {
                    value
                  }
                  slug {
                    value
                  }
                }
              }
            }
          }
          tags {
            value {
              ... on kontent_item_tag {
                id
                elements {
                  slug {
                    value
                  }
                  tag {
                    value
                  }
                }
              }
            }
          }
          first_content_section {
            value
            links {
              link_id
              url_slug
            }
          }
          main_content_section {
            links {
              link_id
              url_slug
            }
            value
          }
          featured_image {
            value {
              description
              url
              width
              height
              fluid(maxWidth: 2048, quality: 75) {
                ...KontentAssetFluid_withWebp_noBase64
              }
            }
          }
          global_footer {
            value {
              ... on kontent_item_global_footer {
                id
                elements {
                  global_footer_items {
                    value {
                      ... on kontent_item_review_position {
                        id
                        system {
                          type
                        }
                      }
                    }
                    value {
                      ... on kontent_item_global_footer_blog_banner {
                        id
                        elements {
                          blog_banner_heading {
                            value
                          }
                          sub_heading {
                            value
                          }
                        }
                      }
                      ... on kontent_item_cta_banner {
                        id
                        elements {
                          cta_heading {
                            value
                          }
                          description {
                            value
                          }
                          background_image {
                            value {
                              description
                              fluid(maxWidth: 5000, quality: 40) {
                                ...KontentAssetFluid_withWebp_noBase64
                              }
                            }
                          }
                          button {
                            value {
                              ... on kontent_item_anchor_button {
                                id
                                elements {
                                  anchor_reference {
                                    value {
                                      name
                                    }
                                  }
                                  button_text {
                                    value
                                  }
                                }
                              }
                              ... on kontent_item_button {
                                id
                                elements {
                                  button_text {
                                    value
                                  }
                                  linked_page {
                                    value {
                                      ...PageItemFragment
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      ... on kontent_item_form_banner {
                        id
                        elements {
                          form_heading {
                            value
                          }
                          form_sub_heading {
                            value
                          }
                          form {
                            value {
                              ... on kontent_item_form {
                                id
                                elements {
                                  submission_message_body {
                                    value
                                  }
                                  submission_message_heading {
                                    value
                                  }
                                  submit_button_text {
                                    value
                                  }
                                  fields {
                                    value {
                                      ... on kontent_item_form_field {
                                        id
                                        elements {
                                          field_type {
                                            value {
                                              codename
                                            }
                                          }
                                          hidden_field_value {
                                            value
                                          }
                                          label {
                                            value
                                          }
                                          options {
                                            value
                                          }
                                          required {
                                            value {
                                              codename
                                            }
                                          }
                                          form_field {
                                            value {
                                              codename
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      ... on kontent_item_logo_slider {
                        id
                        elements {
                          logo_slider_heading {
                            value
                          }
                          logo_slider {
                            value {
                              ... on kontent_item_image {
                                id
                                elements {
                                  single_image {
                                    value {
                                      description
                                      fluid(maxWidth: 768, quality: 65) {
                                        ...KontentAssetFluid_withWebp_noBase64
                                      }
                                    }
                                  }
                                }
                              }
                              ... on kontent_item_image_link {
                                id
                                elements {
                                  image {
                                    value {
                                      fluid(maxWidth: 768, quality: 65) {
                                        ...KontentAssetFluid_withWebp_noBase64
                                      }
                                      description
                                    }
                                  }
                                  internal_link {
                                    value {
                                      ...PageItemFragment
                                    }
                                  }
                                  url {
                                    value
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allKontentItemPost(
      filter: { elements: { slug: { value: { eq: $slug } } } }
    ) {
      nodes {
        elements {
          heading {
            value
          }
          published_date {
            value(formatString: "MMMM DD, YYYY")
          }
          seo_metadata__meta_title {
            value
          }
          seo_metadata__meta_description {
            value
          }
          author {
            value {
              ... on kontent_item_author {
                id
                elements {
                  name {
                    value
                  }
                }
              }
            }
          }
          category {
            value {
              ... on kontent_item_category {
                elements {
                  category_name {
                    value
                  }
                  slug {
                    value
                  }
                }
              }
            }
          }
          tags {
            value {
              ... on kontent_item_tag {
                id
                elements {
                  slug {
                    value
                  }
                  tag {
                    value
                  }
                }
              }
            }
          }
          content_blocks {
            value {
              ... on kontent_item_content_block {
                id
                __typename
                elements {
                  heading {
                    value
                  }
                  sub_heading {
                    value
                  }
                  content {
                    value
                    links {
                      link_id
                      url_slug
                    }
                  }
                  image {
                    value {
                      fluid(maxWidth: 768, quality: 65) {
                        ...KontentAssetFluid_withWebp_noBase64
                      }
                      description
                    }
                  }
                  buttons {
                    value {
                      ... on kontent_item_anchor_button {
                        id
                        elements {
                          anchor_reference {
                            value {
                              name
                            }
                          }
                          button_text {
                            value
                          }
                        }
                      }
                      ... on kontent_item_button {
                        id
                        elements {
                          button_text {
                            value
                          }
                          linked_page {
                            value {
                              ...PageItemFragment
                            }
                          }
                        }
                      }
                    }
                  }
                  alt_background_color {
                    value {
                      codename
                    }
                  }
                  image_gallery {
                    value {
                      ... on kontent_item_image_gallery {
                        id
                        elements {
                          gallery {
                            value {
                              ... on kontent_item_image {
                                id
                                elements {
                                  single_image {
                                    value {
                                      description
                                      fluid(maxWidth: 768, quality: 65) {
                                        ...KontentAssetFluid_withWebp_noBase64
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  html {
                    value
                  }
                }
              }
              ... on kontent_item_multi_content_block {
                id
                __typename
                elements {
                  multi_block_heading {
                    value
                  }
                  sub_heading {
                    value
                  }
                  alt_background_color {
                    value {
                      codename
                    }
                  }
                  content_block {
                    value {
                      ... on kontent_item_content_block {
                        id
                        elements {
                          heading {
                            value
                          }
                          content {
                            value
                            links {
                              link_id
                              url_slug
                            }
                          }
                          image {
                            value {
                              description
                              fluid(maxWidth: 768, quality: 65) {
                                ...KontentAssetFluid_withWebp_noBase64
                              }
                            }
                          }
                          alt_background_color {
                            value {
                              codename
                            }
                          }
                          buttons {
                            value {
                              ... on kontent_item_anchor_button {
                                id
                                elements {
                                  button_text {
                                    value
                                  }
                                  anchor_reference {
                                    value {
                                      codename
                                    }
                                  }
                                }
                              }
                              ... on kontent_item_button {
                                id
                                elements {
                                  button_text {
                                    value
                                  }
                                  linked_page {
                                    value {
                                      ...PageItemFragment
                                    }
                                  }
                                }
                              }
                            }
                          }
                          image_gallery {
                            value {
                              ... on kontent_item_image_gallery {
                                id
                                elements {
                                  gallery {
                                    value {
                                      ... on kontent_item_image {
                                        id
                                        elements {
                                          single_image {
                                            value {
                                              description
                                              fluid(
                                                maxWidth: 768
                                                quality: 65
                                              ) {
                                                ...KontentAssetFluid_withWebp_noBase64
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          html {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on kontent_item_content_banner {
                id
                __typename
                elements {
                  background_image {
                    value {
                      fluid(maxWidth: 1920, quality: 85) {
                        ...KontentAssetFluid_withWebp_noBase64
                      }
                      description
                    }
                  }
                  content_slider {
                    value {
                      ... on kontent_item_content_block {
                        id
                        elements {
                          heading {
                            value
                          }
                          sub_heading {
                            value
                          }
                          content {
                            value
                          }
                          image {
                            value {
                              fluid(maxWidth: 768, quality: 65) {
                                ...KontentAssetFluid_withWebp_noBase64
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on kontent_item_faq_group {
                id
                __typename
                elements {
                  faq_list {
                    value {
                      ... on kontent_item_faq {
                        id
                        elements {
                          image {
                            value {
                              description
                              fluid(maxWidth: 768, quality: 65) {
                                ...KontentAssetFluid_withWebp_noBase64
                              }
                            }
                          }
                          question {
                            value
                          }
                          answer {
                            value
                            links {
                              link_id
                              url_slug
                            }
                          }
                        }
                      }
                    }
                  }
                  heading {
                    value
                  }
                  sub_heading {
                    value
                  }
                }
              }
              ... on kontent_item_faq {
                id
                __typename
                elements {
                  question {
                    value
                  }
                  answer {
                    value
                    links {
                      link_id
                      url_slug
                    }
                  }
                  image {
                    value {
                      fluid(maxWidth: 768, quality: 65) {
                        ...KontentAssetFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
              ... on kontent_item_form_banner {
                id
                __typename
                elements {
                  form_heading {
                    value
                  }
                  form_sub_heading {
                    value
                  }
                  form {
                    value {
                      ... on kontent_item_form {
                        id
                        elements {
                          submission_message_body {
                            value
                          }
                          submission_message_heading {
                            value
                          }
                          submit_button_text {
                            value
                          }
                          fields {
                            value {
                              ... on kontent_item_form_field {
                                id
                                elements {
                                  field_type {
                                    value {
                                      codename
                                    }
                                  }
                                  hidden_field_value {
                                    value
                                  }
                                  label {
                                    value
                                  }
                                  options {
                                    value
                                  }
                                  required {
                                    value {
                                      codename
                                    }
                                  }
                                  form_field {
                                    value {
                                      codename
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on kontent_item_image_gallery {
                id
                __typename
                elements {
                  gallery {
                    value {
                      ... on kontent_item_image {
                        id
                        elements {
                          single_image {
                            value {
                              description
                              fluid(maxWidth: 768, quality: 65) {
                                ...KontentAssetFluid_withWebp_noBase64
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on kontent_item_image_navigation {
                id
                __typename
                elements {
                  heading {
                    value
                  }
                  description {
                    value
                  }
                  navigation {
                    value {
                      ... on kontent_item_image_link {
                        id
                        elements {
                          image {
                            value {
                              description
                              fluid(maxWidth: 768, quality: 75) {
                                ...KontentAssetFluid_withWebp_noBase64
                              }
                            }
                          }
                          internal_link {
                            value {
                              ...PageItemFragment
                            }
                          }
                          url {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on kontent_item_logo_slider {
                id
                __typename
                elements {
                  logo_slider_heading {
                    value
                  }
                  logo_slider {
                    value {
                      ... on kontent_item_image {
                        id
                        elements {
                          single_image {
                            value {
                              description
                              fluid(maxWidth: 768, quality: 65) {
                                ...KontentAssetFluid_withWebp_noBase64
                              }
                            }
                          }
                        }
                      }
                      ... on kontent_item_image_link {
                        id
                        elements {
                          image {
                            value {
                              fluid(maxWidth: 768, quality: 65) {
                                ...KontentAssetFluid_withWebp_noBase64
                              }
                              description
                            }
                          }
                          internal_link {
                            value {
                              ...PageItemFragment
                            }
                          }
                          url {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on kontent_item_navigation_block {
                id
                __typename
                elements {
                  navigation_tiles {
                    value {
                      ... on kontent_item_navigation_tile {
                        id
                        elements {
                          background_image {
                            value {
                              fluid(maxWidth: 768, quality: 65) {
                                ...KontentAssetFluid_withWebp_noBase64
                              }
                              description
                            }
                          }
                          cta {
                            value
                          }
                          description {
                            value
                          }
                          heading {
                            value
                          }
                          icon {
                            value {
                              url
                              description
                            }
                          }
                          internal_link {
                            value {
                              ...PageItemFragment
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on kontent_item_service_comparison {
                id
                __typename
                elements {
                  services {
                    value {
                      ... on kontent_item_service {
                        id
                        elements {
                          name {
                            value
                          }
                          image_gallery {
                            value {
                              ... on kontent_item_image {
                                id
                                elements {
                                  single_image {
                                    value {
                                      description
                                      fluid(maxWidth: 768, quality: 75) {
                                        ...KontentAssetFluid_withWebp_noBase64
                                      }
                                      description
                                    }
                                  }
                                }
                              }
                            }
                          }
                          pests_covered {
                            value {
                              ... on kontent_item_pest {
                                id
                                __typename
                                elements {
                                  name {
                                    value
                                  }
                                }
                              }
                            }
                          }
                          price {
                            value
                          }
                          price_term {
                            value {
                              codename
                            }
                          }
                          pricing_disclaimer {
                            value
                          }
                          service_highlights {
                            value {
                              ... on kontent_item_service_highlight {
                                id
                                elements {
                                  heading {
                                    value
                                  }
                                  description {
                                    value
                                  }
                                }
                              }
                            }
                          }
                          short_description {
                            value
                          }
                          icon {
                            value {
                              url
                            }
                          }
                          button {
                            value {
                              ... on kontent_item_anchor_button {
                                id
                                elements {
                                  button_text {
                                    value
                                  }
                                  anchor_reference {
                                    value {
                                      name
                                    }
                                  }
                                }
                              }
                            }
                          }
                          image {
                            value {
                              description
                              fluid(maxWidth: 768, quality: 65) {
                                ...KontentAssetFluid_withWebp_noBase64
                              }
                            }
                          }
                          price_tiers {
                            value {
                              ... on kontent_item_service_price_tier {
                                id
                                elements {
                                  ending_footage {
                                    value
                                  }
                                  footage_type {
                                    value {
                                      codename
                                    }
                                  }
                                  initial_fee {
                                    value
                                  }
                                  price {
                                    value
                                  }
                                  starting_footage {
                                    value
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on kontent_item_image {
                id
                __typename
                elements {
                  single_image {
                    value {
                      description
                      fluid(maxWidth: 768, quality: 75) {
                        ...KontentAssetFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
          featured_image {
            value {
              description
              url
              width
              height
              fluid(maxWidth: 2048, quality: 75) {
                ...KontentAssetFluid_withWebp_noBase64
              }
            }
          }
          show_rating_banner {
            value {
              codename
            }
          }
          global_footer {
            value {
              ... on kontent_item_global_footer {
                id
                elements {
                  global_footer_items {
                    value {
                      ... on kontent_item_review_position {
                        id
                        system {
                          type
                        }
                      }
                      ... on kontent_item_global_footer_blog_banner {
                        id
                        elements {
                          blog_banner_heading {
                            value
                          }
                          sub_heading {
                            value
                          }
                        }
                      }
                      ... on kontent_item_cta_banner {
                        id
                        elements {
                          cta_heading {
                            value
                          }
                          description {
                            value
                          }
                          background_image {
                            value {
                              fluid(maxWidth: 5000, quality: 40) {
                                ...KontentAssetFluid_withWebp_noBase64
                              }
                            }
                          }
                          button {
                            value {
                              ... on kontent_item_anchor_button {
                                id
                                elements {
                                  anchor_reference {
                                    value {
                                      name
                                    }
                                  }
                                  button_text {
                                    value
                                  }
                                }
                              }
                              ... on kontent_item_button {
                                id
                                elements {
                                  button_text {
                                    value
                                  }
                                  linked_page {
                                    value {
                                      ...PageItemFragment
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      ... on kontent_item_form_banner {
                        id
                        elements {
                          form_heading {
                            value
                          }
                          form_sub_heading {
                            value
                          }
                          form {
                            value {
                              ... on kontent_item_form {
                                id
                                elements {
                                  submission_message_body {
                                    value
                                  }
                                  submission_message_heading {
                                    value
                                  }
                                  submit_button_text {
                                    value
                                  }
                                  fields {
                                    value {
                                      ... on kontent_item_form_field {
                                        id
                                        elements {
                                          field_type {
                                            value {
                                              codename
                                            }
                                          }
                                          hidden_field_value {
                                            value
                                          }
                                          label {
                                            value
                                          }
                                          options {
                                            value
                                          }
                                          required {
                                            value {
                                              codename
                                            }
                                          }
                                          form_field {
                                            value {
                                              codename
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      ... on kontent_item_logo_slider {
                        id
                        elements {
                          logo_slider_heading {
                            value
                          }
                          logo_slider {
                            value {
                              ... on kontent_item_image {
                                id
                                elements {
                                  single_image {
                                    value {
                                      description
                                      fluid(maxWidth: 768, quality: 65) {
                                        ...KontentAssetFluid_withWebp_noBase64
                                      }
                                    }
                                  }
                                }
                              }
                              ... on kontent_item_image_link {
                                id
                                elements {
                                  image {
                                    value {
                                      fluid(maxWidth: 768, quality: 65) {
                                        ...KontentAssetFluid_withWebp_noBase64
                                      }
                                      description
                                    }
                                  }
                                  internal_link {
                                    value {
                                      ...PageItemFragment
                                    }
                                  }
                                  url {
                                    value
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    kontentItemSettings {
      elements {
        pestroutes_reviews {
          value {
            codename
          }
        }
        enable_buy_online {
          value {
            codename
          }
        }
        corporate_head_office {
          value {
            ... on kontent_item_office {
              id
              elements {
                tracking_number {
                  value
                }
                phone_number {
                  value
                }
                name {
                  value
                }
                city {
                  value
                }
                state {
                  value {
                    codename
                    name
                  }
                }
                zip {
                  value
                }
                address_line_1 {
                  value
                }
                address_line_2 {
                  value
                }
              }
            }
          }
        }
        reviews {
          value {
            ... on kontent_item_review {
              id
              elements {
                heading {
                  value
                }
                location {
                  value {
                    ... on kontent_item_service_area {
                      id
                      __typename
                      elements {
                        label {
                          value
                        }
                        service_area_name {
                          value
                        }
                        slug {
                          value
                        }
                      }
                    }
                  }
                }
                date {
                  value(formatString: "DD MMMM YYYY")
                }
                rating {
                  value {
                    codename
                  }
                }
                review {
                  value
                }
                reviewer {
                  value
                }
                review_source_link {
                  value
                }
              }
            }
          }
        }
        review_images {
          value {
            ... on kontent_item_review_image {
              id
              elements {
                review_image {
                  value {
                    fluid(maxWidth: 768) {
                      ...KontentAssetFluid_withWebp_noBase64
                    }
                    description
                  }
                }
              }
            }
          }
        }
        google_rating_banner {
          value {
            ... on kontent_item_google_rating_banner {
              id
              elements {
                google_url {
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`
export default Blog
