// extracted by mini-css-extract-plugin
export var bannerContent = "hero-module--banner-content--5XGCh";
export var bigNumber = "hero-module--big-number--5YNyD";
export var hero = "hero-module--hero--6YUHA";
export var heroLp = "hero-module--hero-lp--Hy4VC";
export var homePageBannerLinks = "hero-module--home-page-banner-links--4hOWm";
export var landingContent = "hero-module--landing-content--2eeKg";
export var opacity = "hero-module--opacity--5xOwy";
export var price = "hero-module--price--4Vq3m";
export var promo = "hero-module--promo--7469m";
export var smallText = "hero-module--small-text--219Pl";