import React from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import * as styles from "./hero.module.scss"
import { RichTextElement } from "@kentico/gatsby-kontent-components"

const Hero = ({ banner, currentPage }) => {
  const { mobile, background_image, heading, subheading, buttons } = banner

  const getButtons = () => {
    if (buttons.value.length > 0) {
      return buttons.value.map(({ elements, id }) => {
        const { button_text, linked_page, anchor_reference } = elements
        const anchorReference = anchor_reference?.value[0].name || "form-banner"
        if (linked_page !== undefined) {
          const { slug } = linked_page.value[0].elements
          return (
            <Link key={id} to={`/${slug.value}`} className="primary-button">
              {button_text.value}
            </Link>
          )
        } else {
          return (
            <AnchorLink
              to={`${currentPage}#${anchorReference}`}
              className="primary-button"
              key={id}
            >
              {button_text.value}
            </AnchorLink>
          )
        }
      })
    }
  }

  const style = typeof window !== "undefined" && window.innerWidth < 768
    ? {
      background: `linear-gradient(rgba(68,68,68,0.75), rgba(68,68,68,0.75)), url(${mobile.value[0].fluid.src})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover !important",
    }
    : {
      background: `url(${background_image.value[0].fluid.src})`,
    }

  return (
    <>
      <div
        style={style}
        className={`PrimaryColorBg ${styles.hero}`}
        id="hero-override"
      >
        <section className="container">
          <div className={styles.bannerContent}>
            <h1>{heading.value}</h1>
            <RichTextElement value={subheading.value} />
            <div className={styles.homePageBannerLinks}>{getButtons()}</div>
          </div>
        </section>
      </div>
    </>
  )
}
Hero.defaultProps = {
  banner: {},
}
export default Hero
