import React, { useContext } from "react"
import { AppContext } from "../context/context"

import BgImg from "gatsby-background-image"
import * as styles from "./serviceHero.module.scss"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import { Link } from "gatsby"
const ServiceHero = ({ banner }) => {
  const { size } = useContext(AppContext)

  const { background_image, services } = banner
  const { button, heading, subheading, price, price_term } =
    services.value[0].elements
  const { button_text, linked_page } = button.value[0].elements
  const { slug } = linked_page.value[0].elements
  const newPrice = price.value.split(".")
  const backgroundOverlay = [
    background_image.value[0].fluid,
    `linear-gradient(rgba(68,68,68,0.75), rgba(68,68,68,0.75))`,
  ].reverse()

  const getTerm = priceTerm => {
    switch (priceTerm) {
      case "weekly":
        return "wk"
      case "bi_weekly":
        return "bw"
      case "monthly":
        return "mo"
      case "quarterly":
        return "qtly"
      case "annually":
        return "yr"
      case "one_time":
        return "service"
      default:
        break
    }
  }

  if (size < 768) {
    return (
      <BgImg
        fluid={backgroundOverlay}
        alt={backgroundOverlay.description}
        className={styles.hero}
      >
        <section className={`container ${styles.bannerContent}`}>
          <h1>{heading.value}</h1>
          <RichTextElement value={subheading.value} />
          <div className={styles.homePageBannerLinks}>
            {price.value && (
              <div >
                <div className={styles.price}>
                  <span >$</span>
                  <span className={styles.bigNumber}>{newPrice[0]}</span>
                  <span>{newPrice[1]}</span>
                  <span className="regular">
                    /{getTerm(price_term.value[0].codename)}
                  </span>
                </div>
              </div>
            )}
            <Link to={slug.value} className="primary-button">
              {button_text.value}
            </Link>
          </div>
        </section>
      </BgImg>
    )
  } else {
    return (
      <BgImg
        fluid={background_image.value[0].fluid}
        alt={background_image.value[0].description}
        className={styles.hero}
      >
        <section className="container">
          <div className={styles.bannerContent}>
            <h1>{heading.value}</h1>
            <p>{subheading.value}</p>
            <div className={styles.homePageBannerLinks}>
              {price.value && (
                <div >
                  <div className={styles.price}>
                    <span>$</span>
                    <span className={styles.bigNumber}>{newPrice[0]}</span>
                    <span>{newPrice[1]}</span>
                    <span className="regular">
                      /{getTerm(price_term.value[0].codename)}
                    </span>
                  </div>
                </div>
              )}
              <Link to={slug.value} className="primary-button">
                {button_text.value}
              </Link>
            </div>
          </div>
        </section>
      </BgImg>
    )
  }
}
ServiceHero.defaultProps = {
  banner: {},
  price: "29.00",
}
export default ServiceHero
