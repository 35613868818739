import React from "react"
import * as styles from "./blog.module.scss"
const BlogHero = ({ title, published }) => {
  return (
    <section className={styles.hero}>
      <h1>{title}</h1>
      <p>{published}</p>
    </section>
  )
}

export default BlogHero
