import { Link } from "gatsby"
import React, { useContext } from "react"
import Img from "gatsby-image"
import * as styles from "./pest-library.module.scss"
import { AppContext } from "../context/context"

const PestTile = ({ pest }) => {
  const { slug, name, image } = pest
  const { pestLibraryActive, pestLibrarySlug } = useContext(AppContext)
  const pestSlug = pestLibraryActive
    ? `/${pestLibrarySlug}/${slug.value}`
    : `/${slug.value}`

  return (
    <Link to={pestSlug} className={styles.pest}>
      <Img
        fluid={image.value[0].fluid}
        alt={image.value[0].description}
        className={styles.pestImage}
      />
      <h3>{name.value}</h3>
    </Link>
  )
}

export default PestTile
