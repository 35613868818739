import React, { useEffect, useState } from "react"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Layout from "../components/layout"
import SEO from "../components/seo/seo"
import SimpleHero from "../components/simple-hero/SimpleHero"
import FormStub from "../components/form-stub/FormStub"
import ContentBlocks from "../components/content-blocks/ContentBlocks"
import GlobalFooter from "../components/global-footer/GlobalFooter"
import BlogDetailCard from "../components/blog/BlogDetailCard"
import * as styles from "../components/blog/blog.module.scss"
import GoogleRating from "../components/google-rating-banner/GoogleRating"
import { graphql, Link } from "gatsby"
const BlogList = ({ pageContext, data }) => {
  const { currentPage, numBlogPages, category, tag, slug, limit } = pageContext
  let originalNum = numBlogPages > 10 ? 10 : numBlogPages
  const [pagesToShow, setPagesToShow] = useState(originalNum) // Default value

  useEffect(() => {
    // Update `pagesToShow` when the window inner width changes
    const handleResize = () => {
      if (window.innerWidth < 450 && numBlogPages >= 3) {
        setPagesToShow(3)
      } else if (window.innerWidth < 700 && numBlogPages >= 5) {
        setPagesToShow(5)
      } else {
        setPagesToShow(originalNum)
      }
    }

    // Initial setting
    handleResize()

    // Add event listener to handle window resize
    window.addEventListener("resize", handleResize)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  let prevPage
  let nextPage
  let homeSlug
  let pageSlug
  if (!category && !tag) {
    homeSlug = "/blog"
    pageSlug = `/blog/posts/p/`
    prevPage = currentPage - 1 === 1 ? homeSlug : pageSlug + (currentPage - 1)
    nextPage = pageSlug + (currentPage + 1)
  } else if (category) {
    homeSlug = `/blog/categories/${slug}`
    pageSlug = homeSlug + `/p/`
    prevPage = currentPage - 1 === 1 ? homeSlug : pageSlug + (currentPage - 1)
    nextPage = pageSlug + (currentPage + 1)
  } else if (tag) {
    homeSlug = `/blog/tags/${slug}`
    pageSlug = homeSlug + `/p/`
    prevPage = currentPage - 1 === 1 ? homeSlug : pageSlug + (currentPage - 1)
    nextPage = pageSlug + (currentPage + 1)
  }

  const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2))
  const endPage = Math.min(startPage + pagesToShow - 1, numBlogPages)

  const isFirst = currentPage === 1
  const isLast = currentPage === numBlogPages
  const [page] = data.allKontentItemPage.nodes
  const { blogParent } = data.nodeEnvs
  const {
    banner,
    content_blocks,
    seo_metadata__meta_title,
    seo_metadata__meta_description,
    global_footer,
    cta_form_stub,
  } = page.elements
  const {
    pestroutes_reviews,
    corporate_head_office,
    reviews,
    enable_buy_online,
    review_images,
    google_rating_banner,
  } = data.kontentItemSettings.elements
  const { tracking_number } = corporate_head_office.value[0].elements
  let postArr = [
    ...data.allKontentItemPostCopy.edges,
    ...data.allKontentItemPost.edges,
  ]
  const google_url = google_rating_banner.value[0]?.elements.google_url
  if (category || tag) {
    let filteredPosts
    if (category) {
      filteredPosts = postArr.filter(
        post =>
          post.node.elements.category.value[0]?.elements.category_name.value ===
          category
      )
      // .slice(catSkip, catSkip + catLimit)
    } else {
      filteredPosts = postArr.filter(post => {
        const tags = post.node.elements.tags.value

        for (let i = 0; i < tags.length; i++) {
          const curTag = tags[i].elements.tag.value
          if (curTag === tag) return true
        }
        return false
      })
      // .slice(tagSkip, tagSkip + tagLimit)
    }
    postArr = [...filteredPosts]
  }

  let currentPageCards = postArr.filter(
    (_, i) => i < currentPage * limit && i >= currentPage * limit - limit
  )

  const {
    breadcrumb: { crumbs },
    noIndex,
  } = pageContext
  // removes google rating banner from content block
  const blocksWithoutGoogleRatingBanner = content_blocks.value.filter(
    block => block.__typename !== "kontent_item_google_rating_banner"
  )
  const contentBlocksWithoutGoogle = { value: blocksWithoutGoogleRatingBanner }

  return (
    <Layout>
      <SEO
        title={seo_metadata__meta_title.value}
        description={seo_metadata__meta_description.value}
        noIndex={noIndex}
      />
      <SimpleHero banner={banner.value[0]} tag={tag} category={category} />
      {google_url?.value && <GoogleRating url={google_url} />}
      {cta_form_stub?.value.length > 0 && (
        <FormStub
          content={cta_form_stub.value[0]}
          phoneNumber={tracking_number.value}
          currentPage={pageContext.breadcrumb.location}
        />
      )}
      {tag || category ? (
        <section>
          <Breadcrumb
            crumbs={crumbs.slice(0, 4)}
            crumbSeparator=" > "
            hiddenCrumbs={["/blog/tags", "/blog/categories", "/p"]}
            crumbLabel={tag ? tag : category}
          />
          <h2 className={styles.hTwo}>
            {tag ? `Articles Under - ${tag}` : `Articles Under - ${category}`}
          </h2>
        </section>
      ) : (
        <ContentBlocks
          content={contentBlocksWithoutGoogle}
          enableBuyOnline={enable_buy_online}
        />
      )}
      <section className={`container ${styles.blogCardGrid}`}>
        {currentPageCards.map(({ node }) => {
          return (
            <BlogDetailCard key={node.id} blog={node} blogParent={blogParent} />
          )
        })}
      </section>
      {numBlogPages > 1 && (
        <section className={`container ${styles.blogPagination}`}>
          {!isFirst && (
            <Link to={prevPage} className={styles.pageLink}>
              Previous
            </Link>
          )}
          {Array.from({ length: endPage - startPage + 1 }, (_, i) => {
            const pageNumber = startPage + i
            return (
              <Link
                key={pageNumber}
                to={pageNumber === 1 ? homeSlug : pageSlug + pageNumber}
                className={
                  pageNumber === currentPage
                    ? `${styles.pageLink} ${styles.activePage}`
                    : `${styles.pageLink}`
                }
              >
                {pageNumber}
              </Link>
            )
          })}
          {!isLast && (
            <Link to={nextPage} className={styles.pageLink}>
              Next
            </Link>
          )}
        </section>
      )}
      {global_footer.value.length > 0 && (
        <GlobalFooter
          content={global_footer}
          phone={tracking_number}
          pestroutesReviews={pestroutes_reviews}
          reviewImages={review_images}
          staticReviews={reviews}
        />
      )}
    </Layout>
  )
}

export const getPage = graphql`
  query {
    allKontentItemPage(
      filter: { elements: { slug: { value: { eq: "blog" } } } }
    ) {
      nodes {
        elements {
          banner {
            value {
              ... on kontent_item_simple_hero_banner {
                id
                elements {
                  heading {
                    value
                  }
                  background_image {
                    value {
                      description
                      fluid(maxWidth: 5000, quality: 65) {
                        ...KontentAssetFluid_withWebp_noBase64
                      }
                    }
                  }
                  background_color {
                    value {
                      name
                    }
                  }
                }
              }
            }
          }
          cta_form_stub {
            value {
              ... on kontent_item_form_stub_banner {
                id
                elements {
                  icon {
                    value {
                      url
                      description
                    }
                  }
                  heading {
                    value
                  }
                  button_text {
                    value
                  }
                  subheading {
                    value
                  }
                  fields {
                    value {
                      ... on kontent_item_form_field {
                        id
                        elements {
                          form_field {
                            value {
                              codename
                            }
                          }
                          field_type {
                            value {
                              codename
                            }
                          }
                          label {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          seo_metadata__canonical_link {
            value
          }
          content_blocks {
            value {
              ... on kontent_item_content_block {
                id
                __typename
                elements {
                  heading {
                    value
                  }
                  sub_heading {
                    value
                  }
                  content {
                    value
                    links {
                      url_slug
                    }
                  }
                  image {
                    value {
                      description
                      fluid(maxWidth: 2048, quality: 65) {
                        ...KontentAssetFluid_withWebp_noBase64
                      }
                    }
                  }
                  html {
                    value
                  }
                  buttons {
                    value {
                      ... on kontent_item_button {
                        id
                        elements {
                          linked_page {
                            value {
                              ...PageItemFragment
                            }
                          }
                          button_text {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on kontent_item_multi_content_block {
                id
                __typename
                elements {
                  multi_block_heading {
                    value
                  }
                  sub_heading {
                    value
                  }
                  content_block {
                    value {
                      ... on kontent_item_content_block {
                        id
                        elements {
                          heading {
                            value
                          }
                          sub_heading {
                            value
                          }
                          image {
                            value {
                              description
                              fluid(maxWidth: 2048, quality: 65) {
                                ...KontentAssetFluid_withWebp_noBase64
                              }
                            }
                          }
                          content {
                            value
                            links {
                              url_slug
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on kontent_item_google_rating_banner {
                id
                __typename
                elements {
                  google_url {
                    value
                  }
                }
              }
            }
          }
          global_footer {
            value {
              ... on kontent_item_global_footer {
                id
                elements {
                  global_footer_items {
                    value {
                      ... on kontent_item_review_position {
                        id
                        system {
                          type
                        }
                      }
                    }
                    value {
                      ... on kontent_item_global_footer_blog_banner {
                        id
                        elements {
                          blog_banner_heading {
                            value
                          }
                          sub_heading {
                            value
                          }
                        }
                      }
                      ... on kontent_item_cta_banner {
                        id
                        elements {
                          cta_heading {
                            value
                          }
                          description {
                            value
                          }
                          background_image {
                            value {
                              description
                              fluid(maxWidth: 5000, quality: 40) {
                                ...KontentAssetFluid_withWebp_noBase64
                              }
                            }
                          }
                          button {
                            value {
                              ... on kontent_item_anchor_button {
                                id
                                elements {
                                  anchor_reference {
                                    value {
                                      name
                                    }
                                  }
                                  button_text {
                                    value
                                  }
                                }
                              }
                              ... on kontent_item_button {
                                id
                                elements {
                                  button_text {
                                    value
                                  }
                                  linked_page {
                                    value {
                                      ...PageItemFragment
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      ... on kontent_item_form_banner {
                        id
                        elements {
                          form_heading {
                            value
                          }
                          form_sub_heading {
                            value
                          }
                          form {
                            value {
                              ... on kontent_item_form {
                                id
                                elements {
                                  submission_message_body {
                                    value
                                  }
                                  submission_message_heading {
                                    value
                                  }
                                  submit_button_text {
                                    value
                                  }
                                  fields {
                                    value {
                                      ... on kontent_item_form_field {
                                        id
                                        elements {
                                          field_type {
                                            value {
                                              codename
                                            }
                                          }
                                          hidden_field_value {
                                            value
                                          }
                                          label {
                                            value
                                          }
                                          options {
                                            value
                                          }
                                          required {
                                            value {
                                              codename
                                            }
                                          }
                                          form_field {
                                            value {
                                              codename
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      ... on kontent_item_logo_slider {
                        id
                        elements {
                          logo_slider_heading {
                            value
                          }
                          logo_slider {
                            value {
                              ... on kontent_item_image {
                                id
                                elements {
                                  single_image {
                                    value {
                                      description
                                      fluid(maxWidth: 768, quality: 65) {
                                        ...KontentAssetFluid_withWebp_noBase64
                                      }
                                    }
                                  }
                                }
                              }
                              ... on kontent_item_image_link {
                                id
                                elements {
                                  image {
                                    value {
                                      fluid(maxWidth: 768, quality: 65) {
                                        ...KontentAssetFluid_withWebp_noBase64
                                      }
                                      description
                                    }
                                  }
                                  internal_link {
                                    value {
                                      ...PageItemFragment
                                    }
                                  }
                                  url {
                                    value
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          page_type {
            value {
              name
            }
          }
          seo_metadata__meta_description {
            value
          }
          seo_metadata__meta_title {
            value
          }
          slug {
            value
          }
        }
      }
    }
    kontentItemSettings {
      elements {
        google_rating_banner {
          value {
            ... on kontent_item_google_rating_banner {
              id
              elements {
                google_url {
                  value
                }
              }
            }
          }
        }
        pestroutes_reviews {
          value {
            codename
          }
        }
        enable_buy_online {
          value {
            codename
          }
        }
        corporate_head_office {
          value {
            ... on kontent_item_office {
              id
              elements {
                tracking_number {
                  value
                }
                phone_number {
                  value
                }
                name {
                  value
                }
                city {
                  value
                }
                state {
                  value {
                    codename
                    name
                  }
                }
                zip {
                  value
                }
                address_line_1 {
                  value
                }
                address_line_2 {
                  value
                }
              }
            }
          }
        }
        reviews {
          value {
            ... on kontent_item_review {
              id
              elements {
                heading {
                  value
                }
                location {
                  value {
                    ... on kontent_item_service_area {
                      id
                      __typename
                      elements {
                        label {
                          value
                        }
                        service_area_name {
                          value
                        }
                        slug {
                          value
                        }
                      }
                    }
                  }
                }
                date {
                  value(formatString: "DD MMMM YYYY")
                }
                rating {
                  value {
                    codename
                  }
                }
                review {
                  value
                }
                reviewer {
                  value
                }
                review_source_link {
                  value
                }
              }
            }
          }
        }
        review_images {
          value {
            ... on kontent_item_review_image {
              id
              elements {
                review_image {
                  value {
                    fluid(maxWidth: 768) {
                      ...KontentAssetFluid_withWebp_noBase64
                    }
                    description
                  }
                }
              }
            }
          }
        }
      }
    }
    allKontentItemPost(
      sort: { fields: elements___published_date___value, order: DESC }
    ) {
      edges {
        node {
          elements {
            heading {
              value
            }
            category {
              value {
                id
                ... on kontent_item_category {
                  id
                  elements {
                    category_name {
                      value
                    }
                  }
                }
              }
            }
            tags {
              value {
                ... on kontent_item_tag {
                  id
                  elements {
                    slug {
                      value
                    }
                    tag {
                      value
                    }
                  }
                }
              }
            }
            published_date {
              value(formatString: "MMM DD, YYYY")
            }
            slug {
              value
            }
            featured_text {
              value
            }
            featured_image {
              value {
                description
                fluid(maxWidth: 1024) {
                  ...KontentAssetFluid_withWebp_noBase64
                }
              }
            }
            seo_metadata__meta_title {
              value
            }
            seo_metadata__meta_description {
              value
            }
            seo_metadata__canonical_link {
              value
            }
          }
          id
        }
      }
    }
    allKontentItemPostCopy(
      sort: { fields: elements___published_date___value, order: DESC }
    ) {
      edges {
        node {
          elements {
            heading {
              value
            }
            category {
              value {
                id
                ... on kontent_item_category {
                  id
                  elements {
                    category_name {
                      value
                    }
                  }
                }
              }
            }
            tags {
              value {
                ... on kontent_item_tag {
                  id
                  elements {
                    slug {
                      value
                    }
                    tag {
                      value
                    }
                  }
                }
              }
            }
            published_date {
              value(formatString: "MMM DD, YYYY")
            }
            slug {
              value
            }
            featured_text {
              value
            }
            featured_image {
              value {
                description
                fluid(maxWidth: 1024) {
                  ...KontentAssetFluid_withWebp_noBase64
                }
              }
            }
            seo_metadata__meta_title {
              value
            }
            seo_metadata__meta_description {
              value
            }
            seo_metadata__canonical_link {
              value
            }
          }
          id
        }
      }
    }
    nodeEnvs {
      blogParent
    }
  }
`
export default BlogList
