import React from 'react'

function ReviewItem(r) {
    let review = {feedback: "",reviewer:"",city:"", state:"", zip:"", date:"", rating:"0"  }
    if(r.review) review = r.review;
    return (
        <div className="reviewRow">
            <div className="reviewColum">
                <div className="review_feedback">{review.feedback}</div>
                <div className="reviewer_details">{review.reviewer} | {review.city}, {review.state} {review.zip}</div>
            </div>
            
            <div className="reviewColum_rating">
                <div className="reviewDate"> {review.date} </div>
                <div className="reviewRating">{review.rating}</div>
                <div className="reviewStars">{printStars(Number(review.rating))}</div>
            </div>
        </div>
    )
}
function printStars(num){
    let stars ="";
    for(let i=0; i<num; i++){
        stars +="★" 
    }
    return stars;
}
export default ReviewItem
