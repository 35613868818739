import React from "react"
import * as styles from "./pest-library.module.scss"
import PestTile from "./PestTile"
const PestList = ({ pests, enabled }) => {
  if (enabled === "kontent_item_pest") {
    return (
      <section className={`container ${styles.pestGrid}`}>
        {pests.value.map(({ elements, id }) => {
          return <PestTile key={id} pest={elements} />
        })}
      </section>
    )
  } else {
    return null
  }
}
export default PestList
