import React from "react"
import * as styles from "./footer.module.scss"
import FooterLocation from "./FooterLocation"
import Credits from "./Credits"
import { AppContext } from "../context/context"
const LandingFooter = ({
  logo,
  location,
  footerNavItems,
  companyName,
  additionalOffices,
  customCTA,
  trackingPhone,
}) => {
  const { size } = React.useContext(AppContext)
  const [brand] = logo.value
  const [office] = location.value
  const numberOfAdditionalOffices = additionalOffices.value.length

  const templateColumnsForAdditionalOffices =
    numberOfAdditionalOffices <= 2
      ? {
          gridTemplateColumns: `repeat(${numberOfAdditionalOffices + 1}, 1fr)`,
        }
      : { gridTemplateColumns: `repeat(3, 1fr)` }

  const isLargeTablet = size >= 1024

  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className={styles.footerInner}>
          <div className={styles.footerLeft}>
            <div className={styles.logo} id="page-footer-logo">
              <img
                src={brand.fluid.src}
                alt={brand.description}
                loading="lazy"
              />
            </div>
            {numberOfAdditionalOffices === 0 && (
              <div className={styles.locationWrapper}>
                <FooterLocation
                  location={office.elements}
                  companyName={companyName}
                />
              </div>
            )}
          </div>

          {isLargeTablet && numberOfAdditionalOffices > 0 && (
            <div
              className={styles.locationWrapper}
              style={templateColumnsForAdditionalOffices}
            >
              <FooterLocation
                location={office.elements}
                companyName={companyName}
              />
              {additionalOffices &&
                additionalOffices.value.map(({ elements, id }) => {
                  return <FooterLocation location={elements} key={id} />
                })}
            </div>
          )}
          {!isLargeTablet && numberOfAdditionalOffices > 0 && (
            <div className={styles.locationWrapper}>
              <FooterLocation
                location={office.elements}
                companyName={companyName}
              />
              {additionalOffices &&
                additionalOffices.value.map(({ elements, id }) => {
                  return <FooterLocation location={elements} key={id} />
                })}
            </div>
          )}

          <div className={styles.footerRight}>
            {customCTA ? (
              <p>{customCTA}</p>
            ) : (
              <p>Request your Free Inspection</p>
            )}
            <a href={`tel:${trackingPhone}`} className={styles.phone}>
              {trackingPhone}
            </a>
          </div>
        </div>
      </div>
      <Credits footerNavItems={footerNavItems} companyName={companyName} />
    </footer>
  )
}

LandingFooter.defaultProps = {
  social: {},
  logo: [],
  location: [],
  trackingPhone: "(207) 888-8888",
}
export default LandingFooter
