import React from "react"
import ServiceArea from "./ServiceArea"

const ServiceAreaList = ({ serviceAreas, enabled, slug }) => {
  if (enabled === "kontent_item_service_area_group") {
    const locationsListSize = serviceAreas.value.length
    const isNotTheLastSectionBlock = index => {
      return index !== locationsListSize - 1
    }
    return (
      <>
        {serviceAreas.value.map(({ elements, id }, index) => {
          const { heading, sub_heading, service_areas } = elements
          return (
            <section className="container locations-list" key={id}>
              {heading.value && <h2>{heading.value}</h2>}
              {sub_heading.value && <h3>{sub_heading.value}</h3>}
              <ul>
                {service_areas.value.map(({ elements, id }) => {
                  return (
                    <ServiceArea
                      key={id}
                      serviceArea={elements}
                      parentSlug={slug}
                    />
                  )
                })}
              </ul>

              {locationsListSize > 1 && isNotTheLastSectionBlock(index) && (
                <hr />
              )}
            </section>
          )
        })}
      </>
    )
  } else {
    return null
  }
}

export default ServiceAreaList
