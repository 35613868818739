import React from "react"
import { Link } from "gatsby"
import * as styles from "./blog.module.scss"
const BlogTags = ({ tagsArr }) => {
  return (
    <article className={`container ${styles.tagWrapper}`}>
      {tagsArr.length ? <span>Tags: </span> : null}
      {tagsArr.map((tag, i) => (
        <Link
          className={styles.tag}
          key={tag.id}
          to={`/blog/tags/${tag.elements.slug.value}`}
        >
          {tag.elements.tag.value}
          {i === tagsArr.length - 1 ? "" : ", "}
        </Link>
      ))}
    </article>
  )
}

export default BlogTags
