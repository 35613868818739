import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { AppContext } from "../context/context"

const BlogSchema = ({ heading, description, published, image }) => {
  const { company_name, primary_logo } = useContext(AppContext)
  return (
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": "${heading}",
            "description": "${description}",
            "datePublished": "${published}",
            "mainEntityOfPage": "True",
            "image": {"@type": "imageObject","url": "${image.url}","height": "${image.height}","width": "${image.width}"},
            "publisher": {"@type": "Organization","name": "${company_name.value}","logo": {"@type": "imageObject","url": "${primary_logo.value[0].url}"}}
          }
        `}
      </script>
    </Helmet>
  )
}

export default BlogSchema
