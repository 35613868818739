import React, { useState, useEffect, useContext } from "react"
import { AppContext } from "../context/context"

const OverallReviews = () => {
  const [average, setAverage] = useState("0")
  const [stars, setStars] = useState("")
  const [starsStats, setStats] = useState({})
  const [reviewsCount, setReviewsCount] = useState(0)

  const {
    isPRClientAndJQueryScriptsLoaded,
    loadPestRoutesClient,
    handleLoadingPRClientAndJQueryScripts,
  } = useContext(AppContext)

  /* eslint-disable */
  const getPestroutesClientReviews = async () => {
    if (typeof PestroutesClient !== "object") {
      return setTimeout(getPestroutesClientReviews, 100)
    }
    const result = await PestroutesClient.getReviewsSummary()

    if (result.success) {
      setAverage(result.reviewsSummary.average)
      let stars = Math.round(Number(result.reviewsSummary.average))
      setStars(printStars(stars))
      setStats(result.reviewsSummary)
      let count = 0
      for (const [key, value] of Object.entries(result.reviewsSummary)) {
        if (key !== "average") count += value
      }
      setReviewsCount(count)
    }
  }

  /* eslint-disable */
  useEffect(() => {
    let mounted = true

    // avoids loading jquery before the LCP is done
    if (typeof PestroutesClient !== "object") {
      setTimeout(() => {
        if (mounted) {
          loadPestRoutesClient()
          handleLoadingPRClientAndJQueryScripts(true)
        }
      }, 200)
    } else {
      handleLoadingPRClientAndJQueryScripts(true)
    }

    return () => {
      mounted = false
    }
  }, [])

  /* eslint-disable */
  useEffect(() => {
    if (!isPRClientAndJQueryScriptsLoaded) return
    getPestroutesClientReviews()
  }, [isPRClientAndJQueryScriptsLoaded])

  const showStarRatingBars = pos => {
    let per = Math.floor((starsStats["STAR_" + pos] * 100) / reviewsCount)
    return (
      <div className="ratingStatsContainer">
        <div>{pos}</div>
        <div className="ratingBarContainer">
          <div className="ratingBar" style={{ width: per + "%" }}></div>
        </div>
        <div>
          {per}% ({starsStats["STAR_" + pos]})
        </div>
      </div>
    )
  }
  return (
    <div className="overallAvgContainer">
      <div className="overallContainer">
        <h2>Overall Average</h2>
        <div className="ratingAvg">{average}</div>
        <div className="ratingAvgStars">{stars}</div>
      </div>
      <div className="statsChartBars">
        {showStarRatingBars(5)}
        {showStarRatingBars(4)}
        {showStarRatingBars(3)}
        {showStarRatingBars(2)}
        {showStarRatingBars(1)}
      </div>
    </div>
  )
}

function printStars(num) {
  let stars = ""
  for (let i = 0; i < num; i++) {
    stars += "★"
  }
  return stars
}

export default OverallReviews
